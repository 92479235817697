import styles from "./footer-signature.module.css";

export default function FooterSignature() {
  return (
    <div
      className={`${styles["footer-signature"]} poppins`}
      style={{ fontWeight: 300 }}
    >
      creators.ae | ©2025
    </div>
  );
}
